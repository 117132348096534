<template>
  <div>
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-cell @click="showStockSelect">
          <van-field
            autocomplete="off"
            v-model="formData.scName"
            name="scName"
            label="仓库"
        /></van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            label="商品编号"
            v-model:value="formData.productCode"
            allow-clear
          />
        </van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            label="商品名称"
            v-model:value="formData.productName"
            allow-clear
          />
        </van-cell>
        <van-cell @click="showDialog">
          <van-field
            autocomplete="off"
            v-model="formData.categoryName"
            name="categoryName"
            label="所属类目名"
          >
          </van-field>
        </van-cell>

        <!-- <van-cell @click="showBrandDialog">
          <van-field
            autocomplete="off"
            label="商品品牌"
            name="brandId"
            v-model:value="formData.brandName"
          ></van-field>
        </van-cell> -->
        <!-- <van-cell>
              <van-field
           autocomplete="off"
                v-model="formData.supplierId"
                name="supplierId"
                label="会员名称"
              >
              </van-field>
            </van-cell> -->
        <!-- <van-cell>
              <van-field
           autocomplete="off"
                v-model="formData.createBy"
                name="createBy"
                label="操作人"
              >
              </van-field>
            </van-cell> -->
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="formData.createStartTime"
            name="createStartTime"
            label="操作日期"
            readonly
            @click="openDatePicker('createStartTime')"
          >
          </van-field>
          <van-field
            autocomplete="off"
            v-model="formData.createEndTime"
            name="createEndTime"
            label="至"
            readonly
            @click="openDatePicker('createEndTime')"
          >
          </van-field>
        </van-cell>
        <!-- <van-cell>
              <van-field
           autocomplete="off"
                v-model="formData.approveBy"
                name="approveBy"
                label="审核人"
              >
              </van-field>
            </van-cell> -->
        <!-- <van-cell>
              <van-field
           autocomplete="off"
                v-model="formData.approveStartTime"
                name="approveStartTime"
                label="审核日期"
                readonly
                @click="openDatePicker('approveStartTime')"
              >
              </van-field>
              <van-field
           autocomplete="off"
                v-model="formData.approveEndTime"
                name="approveEndTime"
                label="至"
                readonly
                @click="openDatePicker('approveEndTime')"
              >
              </van-field>
            </van-cell> -->
        <!-- <van-cell>
              <van-field
           autocomplete="off"
                v-model="formData.purchaser"
                name="purchaser"
                label="采购员"
              >
              </van-field>
            </van-cell> -->
        <van-cell @click="showStatusPicker = true">
          <van-field
            autocomplete="off"
            v-model="formData.statusText"
            label="状态"
            clearable
          ></van-field>
        </van-cell>
        <van-popup
          close-on-click-overlay
          v-model:show="showStatusPicker"
          round
          position="bottom"
        >
          <van-picker
            show-toolbar
            :columns="statusColumns"
            @cancel="showStatusPicker = false"
            @confirm="onStatusSelectConfirm"
          />
        </van-popup>
        <van-cell>
          <van-button
            round
            block
            type="primary"
            native-type="submit"
            :loading="loading"
            loading-text="查询中..."
          >
            查询
          </van-button>
        </van-cell>
      </van-cell-group>

      <DateTimePicker
        :showPicker="showDatePicker"
        @confirm="onConfirm"
        @cancel="showDatePicker = false"
      ></DateTimePicker>
    </van-form>
    <div style="margin: 16px">
      <CustomTable
        :scrollWidth="800"
        :columns="columns"
        :tableData="tableData"
        :total="totalCount"
        :pageIndex="pageIndex"
        :pageSize="pageSize"
        @pageChange="pageChange"
        @rowClick="rowClick"
      ></CustomTable>
    </div>
    <StockSelect
      :visible="stockSelectVisible"
      @confirm="stockSelectConfirm"
    ></StockSelect>
    <CategorySelect
      :visible="categorySelectVisible"
      @confirm="categorySelectConfirm"
    ></CategorySelect>
  </div>
</template>
<script>
import moment from "moment";
import { query } from "../../../service/api/stock/log";
import CustomTable from "../../../components/customTable.vue";
import {
  PURCHASE_ORDER_STATUS,
  getTextByValue,
} from "../../../utils/constants";
import DateTimePicker from "../../../components/dateTimePicker";
import { oneMonthAgoMidnight } from "../../../utils";
import StockSelect from "../../../components/stockSelect.vue";
import CategorySelect from "../../../components/categorySelect.vue";

export default {
  name: "index",
  components: {
    CustomTable,
    StockSelect,
    DateTimePicker,CategorySelect
  },
  data() {
    return {
      formData: {},
      brandSelectVisible: false,
      categorySelectVisible: false,
      stockSelectVisible: false,
      loading: false,
      showStatusPicker: false,
      action: [
        { name: "审核通过", click: "" },
        { name: "审核拒绝", click: "" },
      ],
      showActions: false,
      columns: [
        { field: "scCode", key: "scCode", title: "仓库编号", width: 100 },
        { field: "scName", key: "scName", title: "仓库名称", width: 100 },
        {
          field: "productCode",
          key: "productCode",
          title: "商品编号",
          width: 100,
        },
        {
          field: "productName",
          key: "productName",
          title: "商品名称",
          minWidth: 100,
        },
        {
          field: "categoryName",
          key: "categoryName",
          title: "商品类目",
          width: 100,
        },
        { field: "brandName", key: "brandName", title: "商品品牌", width: 100 },
        {
          field: "stockNum",
          key: "stockNum",
          title: "库存数量",
          align: "right",
          width: 100,
        },
        {
          field: "taxPrice",
          key: "taxPrice",
          title: "含税价格",
          align: "right",
          width: 100,
        },
        {
          field: "taxAmount",
          key: "taxAmount",
          title: "含税金额",
          align: "right",
          width: 100,
        },
      ],
      tableData: [],
      currentDate: "",
      totalCount: 0,
      pageIndex: 1,
      pageSize: 20,
      totalPage: 1,
      formData: {},
      key: "",
      showDatePicker: false,
    };
  },
  computed: {
    statusColumns() {
      return PURCHASE_ORDER_STATUS;
    },
  },
  created() {
    this.formData.createStartTime = oneMonthAgoMidnight();
    this.formData.createEndTime = moment()
      .endOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    this.onSubmit();
  },
  methods: {
    categorySelectConfirm(row) {
      if (!Object.keys(row).length) {
        this.categorySelectVisible = false;
        return;
      }
      this.categorySelectVisible = false;
      this.formData.categoryName = row.name;
      this.formData.categoryId = row.id;
    },
    brandSelectConfirm(row) {
      if (!Object.keys(row).length) {
        this.brandSelectVisible = false;
        return;
      }
      this.brandSelectVisible = false;
      this.formData.brandName = row.name;
      this.formData.brandId = row.id;
    },
    showBrandDialog() {
      this.brandSelectVisible = true;
    },
    showDialog() {
      this.categorySelectVisible = true;
    },
    stockSelectConfirm(row) {
      if (Object.keys(row).length === 0) {
        this.stockSelectVisible = false;
        return;
      }
      this.stockSelectVisible = false;
      this.formData.scName = row.name;
      this.formData.scId = row.id;
    },
    showStockSelect() {
      this.stockSelectVisible = true;
    },
    onSubmit() {
      this.pageIndex = 1;
      this.getList();
    },
    onStatusSelectConfirm(val) {
      this.formData.status = val.value;
      this.formData.statusText = val.text;
      this.showStatusPicker = false;
    },
    showSettingPopup(e) {
      e.stopPropagation();
      this.showActions = true;
    },
    rowClick({ row, rowIndex, e }) {
      console.log("rowClick", row, rowIndex, e);
      //进入详情页
      this.$router.push({
        path: "/retail/outDetail/" + row.id,
      });
    },
    pageChange(pageOptions) {
      this.pageIndex = pageOptions.pageIndex;
      this.pageSize = pageOptions.pageSize;
      console.log(pageOptions, "pageOptions");
      this.getList();
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    openDatePicker(key) {
      this.showDatePicker = true;
      this.key = key;
    },
    onConfirm(value) {
      this.formData[this.key] = value;
      this.showDatePicker = false;
    },
    getList() {
      this.loading = true;
      const params = {
        ...this.formData,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };
      query(params)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.datas;
          this.totalCount = res.data.totalCount;
          this.totalPage = res.data.totalPage;
          this.pageSize = res.data.pageSize;
          this.pageIndex = res.data.pageIndex;
        })
        .catch(() => {
          this.loading = false;
        });
      // console.log(this.formData);
      // let params = {};
      // for (const key in this.formData) {
      //   if (Object.prototype.hasOwnProperty.call(this.formData, key)) {
      //     const value = this.formData[key] === "是" ? true : false;
      //     params[key] = value;
      //   }
      // }
      // update(params).then((res) => {
      //   console.log(res);
      // });
    },
  },
};
</script>
<style lang="less" scoped>
* {
  touch-action: pan-y;
}
.van-cell {
  padding: 5px 2px 2px 15px !important;
}
::v-deep .color1 {
  color: #333;
}
::v-deep .color2 {
  color: #ee0a24;
}
::v-deep .color3 {
  color: #07c160;
}
</style>
